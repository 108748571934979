<template>
    <div class="w-full">
        <!-- update form -->
        <vs-popup :title="`Modifier ${prenom} ${deuxieme_prenom} ${nom}`" fullscreen :active.sync="popupUpdateSuspect">
            <div class="w-full flex justify-end">
                <span class="text-danger mr-1">* </span> = informations requises
            </div>
            <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                <div>Informations sur le suspect</div>
            </div>
            <div class="w-full flex px-5">
                <div class="w-1/2 flex flex-col mr-10">
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="status_suspect"><span class="text-danger">*</span>Status du suspect</label>
                        <vs-select class="w-3/4" autocomplete v-validate="'required'" v-model="status_suspect" >
                            <vs-select-item :key="index" :value="status" :text="status" v-for="(status, index) in ['Nouveau','Non qualifié','En cours de travail','Nurturing']" />
                        </vs-select>
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 font-semibold mr-2" for="civilite"><span class="text-danger">*</span>Nom complet</label>
                        <vs-select class="w-3/4" label="Civilité" autocomplete v-model="civilite" >
                            <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['M.','Mme.','Dr.','Prof.']" />
                        </vs-select>
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 font-semibold mr-2" for="prenom"></label>
                        <vs-input class="w-3/4" type="text" label="Prénom" placeholder="Prénom" name="prenom" v-model="prenom" />
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 font-semibold mr-2" for="deuxieme_prenom"></label>
                        <vs-input class="w-3/4" type="text" label="Deuxième prénom" placeholder="Deuxième prénom" name="deuxieme_prenom" v-model="deuxieme_prenom" />
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 font-semibold mr-2" for="nom"></label>
                        <div class="w-3/4 flex flex-col">
                            <vs-input class="w-full" type="text" label="*Nom" placeholder="Nom" name="nom" v-validate="'required'" v-model="nom" />
                            <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
                        </div>
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="fonction">Fonction</label>
                        <vs-input class="w-3/4" type="text" placeholder="" name="fonction" v-model="fonction" />
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="email">Adresse e-mail</label>
                        <vs-input class="w-3/4" type="email" placeholder="" name="email" v-model="email" />
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="telephone">Téléphone</label>
                        <vs-input class="w-3/4" type="text" placeholder="" name="telephone" v-model="telephone" />
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="mobile">Téléphone mobile</label>
                        <vs-input class="w-3/4" type="text" placeholder="" name="mobile" v-model="mobile" />
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="cote">Cote</label>
                        <vs-select class="w-3/4" autocomplete v-model="cote" >
                            <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Haut','Moyen','Faible']" />
                        </vs-select>
                    </div>
                </div>
                <div class="w-1/2">
                    <div class="w-full flex mt-5">
                    <label class="w-1/4 font-semibold mr-2" for="site_web">Propriétaire du suspect</label>
                    <div class="w-3/4">
                        {{ proprietaire ? proprietaire.nom : '' }}</div>
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="site_web">Site Web</label>
                        <vs-input class="w-3/4" type="text" placeholder="" name="site_web" v-model="site_web" />
                    </div>
                    <div class="w-full flex mt-5">
                    <label class="w-1/4 mr-2" for="societe"><span class="text-danger">*</span>Société</label>
                    <div class="w-3/4 flex flex-col">
                        <vs-input class="w-full" type="text" placeholder="" name="societe" v-validate="'required'" v-model="societe" />
                        <span class="text-danger text-sm" v-show="errors.has('societe')">{{ errors.first('societe') }}</span>
                    </div>
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="secteur">Secteur d'activité</label>
                        <vs-select class="w-3/4" autocomplete v-model="secteur" >
                            <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Agriculture','Vêtements','Banque','Biotechnologie','Produits chimiques','Communications','Construction','Conseil','Formation','Électronique','Énergie','Ingénierie','Divertissement','Environnement','Finance','Alimentation & boissons','Gouvernement','Santé','Hôtellerie','Assurance','Machines','Fabrication','Médias','Non lucratif','Loisirs','Vente au détail','Livraison','Technologie','Télécommunications','Transport','Services','Autre']" />
                        </vs-select>
                    </div>
                    <div class="w-full flex mt-5">
                        <label class="w-1/4 mr-2" for="nmbre_employes">Nombre d'employés</label>
                        <vs-input class="w-3/4" type="text" placeholder="" name="nmbre_employes" v-model="nmbre_employes" />
                    </div>
                    <div class="w-full flex mt-5">
                    <label class="w-1/4 mr-2" for="origine">Origine du suspect</label>
                    <vs-select class="w-3/4" autocomplete v-model="origine" >
                        <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Autre']" />
                    </vs-select>
                    </div>
                </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                <div>Coordonnées</div>
            </div>
            <div class="w-full flex px-5 py-3">
                <div class="w-1/2">
                    <h5 class="text-black font-semibold">Adresse</h5>
                    <div class="w-full mt-5">
                    <vs-textarea row="4" label="Rue" v-model="rue"></vs-textarea>
                    </div>
                    <div class="w-full flex mt-5">
                    <div class="w-3/5 mr-2">
                        <vs-input class="w-full" type="text" label="Ville" placeholder="" name="ville" v-model="ville" />
                    </div>
                    <div class="w-2/5">
                        <vs-input class="w-full" type="text" label="Région/Province" placeholder="" name="region" v-model="region" />
                    </div>
                    </div>
                    <div class="w-full flex mt-5">
                    <div class="w-3/5 mr-2">
                        <vs-input class="w-full" type="text" label="Code postal" placeholder="" name="code_postal" v-model="code_postal" />
                    </div>
                    <div class="w-2/5">
                        <vs-input class="w-full" type="text" label="Pays" placeholder="" name="pays" v-model="pays" />
                    </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex px-5 py-3">
                <div class="w-1/2 mr-10">
                    <span class="font-semibold mr-5">Créé par:</span>
                    <span class="mr-1">
                    {{ proprietaire ? `${proprietaire.nom},` : '' }}
                    </span>
                    <span>
                    {{ createdAt }}
                    </span>
                </div>
                <div class="w-1/2">
                    <span class="font-semibold mr-5">Dernière modification par:</span>
                    <span class="mr-1">
                    {{ proprietaire ? `${proprietaire.nom},` : '' }}
                    </span>
                    <span>{{ updatedAt }}</span>
                </div>
            </div>
            <div class="w-full flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
                <vs-button @click="showUpdateSuspectModal(null)" class="mr-2" color="danger" type="border">Annuler</vs-button>
                <vs-button @click="updateSuspectAndNew()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
                <vs-button @click="updateSuspect()">Enregistrer</vs-button> 
            </div>
        </vs-popup>
        <!-- / update form -->
        <!-- conversion suspect -->
        <vs-popup class="" title="Convertir le suspect" fullscreen :active.sync="popupConversionSuspect">
            <div class="relative w-full">
                <div class="w-full mb-20">
                    <!-- Compte -->
                    <div class="w-full flex flex-col px-5 py-3" style="background-color: #F3F3F3; border: 1px #dcdcdc solid;">
                        <div class="text-lg">Compte</div>
                        <div class="w-full flex mt-5">
                            <div class="w-1/2 mr-5">
                                <div class="font-semibold">
                                    <vs-radio class="mt-1" v-model="createCompte" vs-value="créer compte">Créer</vs-radio>
                                </div>
                                <div class="bg-white rounded-lg px-3 py-5 mt-5" style="border: 1px #dcdcdc solid;">
                                    <label for="nom_compte"> <span class="text-danger">*</span> Nom du compte</label>
                                    <vs-input class="w-full" type="text" placeholder="Nom du compte" name="nom_compte" v-validate="'required'" v-model="nom_compte" />
                                    <span class="text-danger text-sm" v-show="errors.has('nom_compte')">{{ errors.first('nom_compte') }}</span>
                                </div>
                            </div>
                            <div class="w-1/2">
                                <div class="font-semibold">
                                    <vs-radio class="mt-1" v-model="createCompte" vs-value="choisir compte existant">Choisir existant</vs-radio>
                                </div>
                                <div class="px-3 py-5 mt-5">
                                    <label for="search_compte">Rechercher un compte</label>
                                    <vs-input @focus="search_compte_focus = true" @blur="search_compte_focus = false" class="w-full" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherche de comptes correspondants" name="search_compte" v-model="search_compte" />
                                    <div v-if="search_compte_focus" @click="search_compte_focus = true" @mouseover="search_compte_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                                </div>
                                <div class="px-3 py-5 mt-2">
                                    <div class="w-full h-64 bg-white rounded-lg px-3 py-5" style="border: 1px #dcdcdc solid;">
                                        <span>0 Compte correspondants</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Compte -->
                    <!-- Contact -->
                    <div class="w-full flex flex-col px-5 py-3 mt-2" style="background-color: #F3F3F3; border: 1px #dcdcdc solid;">
                        <div class="text-lg">Contact</div>
                        <div class="w-full flex mt-5">
                            <div class="w-1/2 mr-5">
                                <div class="font-semibold">
                                    <vs-radio class="mt-1" v-model="createContact" vs-value="créer contact">Créer</vs-radio>
                                </div>
                                <div class="w-full bg-white rounded-lg px-3 py-5 mt-5" style="border: 1px #dcdcdc solid;">
                                    <div class="w-full">
                                        <label for="civilite_contact">Civilité</label>
                                        <vs-select class="w-full" autocomplete v-model="civilite" >
                                            <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['M.','Mme.','Dr.','Prof.']" />
                                        </vs-select>
                                    </div>
                                    <div class="w-full mt-2">
                                        <label for="prenom_contact">Prénom</label>
                                        <vs-input class="w-full" type="text" placeholder="" name="prenom_contact" v-model="prenom_contact" />
                                    </div>
                                    <div class="w-full mt-2">
                                        <label for="deuxieme_prenom_contact">Deuxième prénom</label>
                                        <vs-input class="w-full" type="text" placeholder="" name="deuxieme_prenom_contact" v-model="deuxieme_prenom_contact" />
                                    </div>
                                    <div class="w-full mt-2">
                                        <label for="nom_contact"><span class="text-danger">*</span>Nom</label>
                                        <vs-input class="w-full" type="text" placeholder="" name="nom_contact" v-validate="'required'" v-model="nom_contact" />
                                        <span class="text-danger text-sm" v-show="errors.has('nom_contact')">{{ errors.first('nom_contact') }}</span>
                                    </div>
                                    <div class="w-full mt-2">
                                        <label for="suffixe_contact">Suffixe</label>
                                        <vs-input class="w-full" type="text" placeholder="" name="suffixe_contact" v-model="suffixe_contact" />
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/2">
                                <div class="font-semibold">
                                    <vs-radio class="mt-1" v-model="createContact" vs-value="choisir contact existant">Choisir existant</vs-radio>
                                </div>
                                <div class="px-3 py-5 mt-5">
                                    <label for="search_contact">Rechercher un contact</label>
                                    <vs-input @focus="search_contact_focus = true" @blur="search_contact_focus = false" class="w-full" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherche de contact correspondants" name="search_contact" v-model="search_contact" />
                                    <div v-if="search_contact_focus" @click="search_contact_focus = true" @mouseover="search_contact_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                                </div>
                                <div class="px-3 py-5 mt-2">
                                    <div class="w-full h-64 bg-white rounded-lg px-3 py-5" style="border: 1px #dcdcdc solid;">
                                        <span>0 Contact correspondants</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Contact -->
                    <!-- Opportunite -->
                    <div class="w-full flex flex-col px-5 py-3 mt-2" style="background-color: #F3F3F3; border: 1px #dcdcdc solid;">
                        <div class="text-lg">Opportunité</div>
                        <div class="w-full flex mt-5">
                            <div class="w-1/2 mr-5">
                                <div class="font-semibold">
                                    <vs-radio class="mt-1" :disabled="ne_pas_creer_opportunite" v-model="createOpportunite" vs-value="créer opportunite">Créer</vs-radio>
                                </div>
                                <div class="bg-white rounded-lg px-3 py-5 mt-5" style="border: 1px #dcdcdc solid;">
                                    <label for="nom_opportunite"> <span class="text-danger">*</span> Nom de l'opportunité</label>
                                    <vs-input class="w-full" :disabled="ne_pas_creer_opportunite" type="text" placeholder="" name="nom_opportunite" v-validate="'required'" v-model="nom_opportunite" />
                                    <span class="text-danger text-sm" v-show="errors.has('nom_opportunite')">{{ errors.first('nom_opportunite') }}</span>
                                </div>
                                <div class="text-sm mt-2">
                                    <vs-checkbox v-model="ne_pas_creer_opportunite">Ne pas créer d'opportunité lors de la conversion</vs-checkbox>
                                </div>
                            </div>
                            <div class="w-1/2">
                                <div class="font-semibold">
                                    <vs-radio class="mt-1" disabled="true" v-model="createOpportunite" vs-value="choisir compte existant">Choisir existant</vs-radio>
                                </div>
                                <div class="px-3 py-5">
                                    <div class="w-full h-64 bg-white rounded-lg px-3 py-5" style="border: 1px #dcdcdc solid;">
                                        <span>0 Opportunité correspondantes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Opportunite -->
                    <!-- proprietaire et status -->
                    <div class="w-full flex flex-col px-5 py-3 mt-2">
                        <div class="w-full flex mt-5">
                            <div class="w-1/2 mr-5">
                                <label for="search_proprietaire"> 
                                    <span class="text-danger">*</span> Propriétaire de l'enregistrement
                                </label>
                                <div v-if="select_prorietaire" class="w-full h-16 rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                                    <div class="w-full h-full flex justify-between items-center rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                                        <div>
                                            <span class="mr-2">{{ select_prorietaire.prenoms }}</span>
                                            <span>{{ select_prorietaire.nom }}</span>
                                        </div>
                                        <div @click="select_prorietaire = null" class="cursor-pointer hover:underline">
                                            <span class="text-danger">supprimer</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <vs-input @input="searchUser" @focus="search_proprietaire_focus = true" class="w-full" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Utilisateur..." name="search_proprietaire" v-model="search_proprietaire" />
                                    <span class="text-danger text-sm" v-show="errors.has('search_proprietaire')">{{ errors.first('search_proprietaire') }}</span>
                                    <div v-if="search_proprietaire_focus" @click="search_proprietaire_focus = true" @mouseover="search_proprietaire_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;">
                                        <ul>
                                            <li @click="selectUser(user)" class="hover:bg-grey-light hover:text-black cursor-pointer px-2 py-3" style="" :key="index" v-for="(user, index) in search_proprietaire_result">
                                                <span class="mr-2">{{ user.prenoms }}</span>
                                                <span>{{ user.nom }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/2">
                                <label for="etape">
                                    <span class="text-danger">*</span> Status converti
                                </label>
                                <vs-select class="w-full" autocomplete v-model="etape" >
                                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Qualifiée']" />
                                </vs-select>
                            </div>
                        </div>
                    </div>
                    <!-- / proprietaire et status -->
                </div>
                <div class="fixed w-full left-0 bottom-0 flex justify-end border-t px-10 py-5" style="background-color: rgb(236, 236, 236);">
                    <vs-button @click="popupConversionSuspect=!popupConversionSuspect" class="mr-2" color="danger" type="border">Annuler</vs-button>
                    <vs-button @click="convertirSuspectCompteContactOpportunite()">Convertir</vs-button> 
                </div>
            </div>
        </vs-popup>
        <!-- /conversion -->
        <div class="w-full flex">
            <div class="w-1/3 mr-5">
                <vx-card class="w-full">
                    <div class="w-full flex items-center">
                        <div class="h-10 w-10 rounded-lg mr-2" style="background-color: #FF5D2D;">
                            <img class="h-10" src="../../../assets/images/lead_120.png" alt="" srcset="">
                        </div>
                        <div class="w-full text-base font-bold">
                            <span class="mr-1">{{ suspect ? suspect.civilite : null }}</span>
                            <span class="mr-1">{{ suspect ? suspect.prenom : null }}</span>
                            <span class="mr-1">{{ suspect ? suspect.deuxieme_prenom : null }}</span>
                            <span class="mr-1">{{ suspect ? suspect.nom : null }}</span>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="btn-group"> 
                            <vs-button @click="showUpdateSuspectModal(suspect)" type="border">Modifier</vs-button>
                            <vs-button @click="confirmDeleteSuspect()" type="border">Supprimer</vs-button> 
                            <vs-button type="border">Cloner</vs-button>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div>Société</div>
                        <div class="text-base">{{ suspect ? suspect.societe : null }}</div>
                    </div>
                    <div class="mt-2">
                        <div>Fonction</div>
                        <div class="text-base">{{ suspect ? suspect.fonction : null }}</div>
                    </div>
                    <div class="mt-2">
                        <div>Téléphone</div>
                        <div class="text-base"><a v-if="suspect" :href="`tel:${suspect.telephone}`">{{ suspect ? suspect.telephone : null }}</a></div>
                        <div class="text-base"><a a v-if="suspect" :href="`tel:${suspect.mobile}`">{{ suspect ? suspect.mobile : null }}</a></div>
                    </div>
                    <div class="mt-2">
                        <div>Adresse e-mail</div>
                        <div class="text-base"><a v-if="suspect" :href="`mailto:${suspect.email}`">{{ suspect ? suspect.email : null }}</a></div>
                    </div>
                </vx-card>
            </div>
            <div class="w-2/3">
                <vx-card class="w-full px-2">
                    <ul class="stepper relative w-full flex rounded p-0">
                        <li @click="status_suspect='Non qualifié'" :class="[status_suspect === 'Non qualifié' ? currentClasse : '', close_qualifier ? completeClasse : '' ,errorClass]" class="stepper__item hover:bg-grey rounded-l-lg cursor-pointer">Non qualifié</li>
                        <li @click="status_suspect='Nouveau'" :class="[status_suspect === 'Nouveau' ? currentClasse : '', close_nouveau ? completeClasse : '' ,errorClass]" class="stepper__item hover:bg-grey cursor-pointer">Nouveau</li>
                        <li @click="status_suspect='Traitement'" :class="[status_suspect === 'Traitement' ? currentClasse : '', close_traitement ? completeClasse : '' ,errorClass]" class="stepper__item hover:bg-grey cursor-pointer">Traitement</li>
                        <li @click="status_suspect='Opportunité'" :class="[status_suspect === 'Opportunité' ? currentClasse : '', close_opportunite ? completeClasse : '', errorClass]" class="stepper__item hover:bg-grey cursor-pointer">Opportunité</li>
                        <li @click="status_suspect='Converti'" :class="[status_suspect === 'Converti' ? currentClasse : '', close_converti ? completeClasse : '', errorClass]" class="stepper__item hover:bg-grey rounded-r-lg cursor-pointer">Converti</li>
                    </ul>
                    <div class="w-full flex justify-between items-center mt-5">
                        <div class="font-semibold">
                            status: {{status_suspect}}
                        </div>
                        <div>
                            <vs-button v-if="status_suspect !== 'Converti'" @click="validateSuspectStatus()">Marquer Status en cours</vs-button>
                            <vs-button v-else @click="showConvertiSuspect()">Sélectionner Status converti(e)</vs-button>
                        </div>
                    </div>
                    <!-- Non qualifié -->
                    <div v-if="status_suspect === 'Non qualifié'" class="w-full flex mt-5">
                        <div class="w-1/2 mr-4">
                            <div class="w-full flex justify-between">
                                <span class="font-bold">Champs clés</span>
                                <span class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Origine du suspect</label>
                                </div>
                                <div class="w-2/3">
                                    <vs-select class="w-full" autocomplete v-model="origine" >
                                        <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Autre']" />
                                    </vs-select>
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="w-full">
                                <span class="font-bold">Guide de réussite</span>
                            </div>
                            <div class="mt-10">
                                <div class="font-bold">
                                    Gardez la trace des informations relatives à vos prospects non qualifiés.
                                </div>
                                <div>
                                    Votre prospect peut être non qualifié s'il n'est pas intéressé par vos produits ou s'il a quitté l'entreprise à laquelle il était associé.
                                </div>
                                <ul class="list-disc mt-1">
                                    <li class="mt-1"> - Consignez les enseignements tirés de l'expérience pour pouvoir vous y référer ultérieurement</li>
                                    <li class="mt-1"> - Sauvegardez les détails de la communication et les informations de contact.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Non qualifié -->
                    <!-- Nouveau -->
                    <div v-if="status_suspect === 'Nouveau'" class="w-full flex mt-5">
                        <div class="w-1/2 mr-4">
                            <div class="w-full flex justify-between">
                                <span class="font-bold">Champs clés</span>
                                <span class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Société*</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-input class="w-full" type="text" placeholder="" name="societe" v-validate="'required'" v-model="societe" />
                                    <span class="text-danger text-sm" v-show="errors.has('societe')">{{ errors.first('societe') }}</span>
                                </div>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Site Web</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-input class="w-full" type="text" placeholder="" name="site_web" v-model="site_web" />
                                </div>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Secteur d'activité</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-select class="w-full" autocomplete v-model="secteur" >
                                        <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Agriculture','Vêtements','Banque','Biotechnologie','Produits chimiques','Communications','Construction','Conseil','Formation','Électronique','Énergie','Ingénierie','Divertissement','Environnement','Finance','Alimentation & boissons','Gouvernement','Santé','Hôtellerie','Assurance','Machines','Fabrication','Médias','Non lucratif','Loisirs','Vente au détail','Livraison','Technologie','Télécommunications','Transport','Services','Autre']" />
                                    </vs-select>
                                </div>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Nombre</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-input class="w-full" type="text" placeholder="" name="nmbre_employes" v-model="nmbre_employes" />
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="w-full">
                                <span class="font-bold">Guide de réussite</span>
                            </div>
                            <div class="mt-10">
                                <div class="font-bold">
                                    Déterminer quelles sont les suspects qualifiées ou non qualifiées.
                                </div>
                                <ul class="list-disc mt-1">
                                    <li class="mt-1"> - Assigner le prospect à un représentant</li>
                                    <li class="mt-1"> - Recueillir des informations clés sur le client potentiel à partir du site Web de son entreprise.</li>
                                    <li class="mt-1"> - Créer un plan pour établir un lien avec ce prospect.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Nouveau -->
                    <!-- Traitement -->
                    <div v-if="status_suspect === 'Traitement'" class="w-full flex mt-5">
                        <div class="w-1/2 mr-4">
                            <div class="w-full flex justify-between">
                                <span class="font-bold">Champs clés</span>
                                <span class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Fonction</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-input class="w-full" type="text" placeholder="" name="fonction" v-model="fonction" />
                                </div>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Adresse e-mail</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-input class="w-full" type="email" placeholder="" name="email" v-model="email" />
                                </div>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Téléphone</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-input class="w-full" type="text" placeholder="" name="telephone" v-model="telephone" />
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="w-full">
                                <span class="font-bold">Guide de réussite</span>
                            </div>
                            <div class="mt-10">
                                <div class="font-bold">
                                    Qualifier les suspects (leads) prometteurs.
                                </div>
                                <ul class="list-disc mt-1">
                                    <li class="mt-1"> - Identifier un contact pour le lead</li>
                                    <li class="mt-1"> - Enregistrez ses coordonnées dans la fiche du prospect.</li>
                                    <li class="mt-1"> - Élaborer un plan de sensibilisation (par exemple, combien de fois allez-vous prendre contact ? Quel sera votre argumentaire ?).</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Traitement -->
                    <!-- Opportunité -->
                    <div v-if="status_suspect === 'Opportunité'" class="w-full flex mt-5">
                        <div class="w-1/2 mr-4">
                            <div class="w-full flex justify-between">
                                <span class="font-bold">Champs clés</span>
                                <span class="text-primary font-bold hover:underline cursor-pointer">Modifier</span>
                            </div>
                            <div class="w-full flex mt-2">
                                <div class="w-1/3">
                                    <label for="">Cote</label>
                                </div>
                                <div class="w-2/3 flex flex-col">
                                    <vs-select class="w-full" autocomplete v-model="cote" >
                                        <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Haut','Moyen','Faible']" />
                                    </vs-select>
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="w-full">
                                <span class="font-bold">Guide de réussite</span>
                            </div>
                            <div class="mt-10">
                                <div class="font-bold">
                                    Nourrissez vos prospects et identifiez les opportunités.
                                </div>
                                <ul class="list-disc mt-1">
                                    <li class="mt-1"> - Identifiez les points douloureux que vous pouvez résoudre pour ce prospect.</li>
                                    <li class="mt-1"> - Déterminez la probabilité de conversion de ce lead.</li>
                                    <li class="mt-1"> - Si cette piste est qualifiée, convertissez-la en opportunité.</li>
                                    <li class="mt-1"> - Veillez à ce que le marketing et les ventes collaborent à l'élaboration de votre processus de qualification des pistes.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /Opportunité -->

                </vx-card>
                <vx-card class="w-full mt-5">
                    <div class="w-full">
                        <vs-tabs class="w-full">
                            <vs-tab class="w-full" label="Activité">
                                <div class="w-full tab-text">
                                    <div class="w-full btn-group mt-2"> 
                                        <vs-button type="border">
                                            <div class="flex items-center">
                                                <feather-icon icon="PhoneCallIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                                                <span>Consigner un appel</span>
                                            </div>
                                        </vs-button>
                                        <vs-button type="border">                                            
                                            <div class="flex items-center">
                                                <feather-icon icon="CheckSquareIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                                                <span>Nouvelle tâche</span>
                                            </div>
                                        </vs-button> 
                                        <vs-button type="border">
                                            <div class="flex items-center">
                                                <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                                                <span>Nouvelle événement</span>
                                            </div>
                                        </vs-button>
                                        <vs-button type="border">
                                            <div class="flex items-center">
                                                <feather-icon icon="MailIcon" svgClasses="h-6 w-6 mr-1 hover:text-primary" />
                                                <span>E-mail</span>
                                            </div>
                                        </vs-button>
                                    </div>
                                    <div class="w-full mt-5">
                                        <vs-collapse class="px-0">
                                            <vs-collapse-item class="rounded-lg" style="background-color: #F3F3F3;">
                                                <div class="font-semibold" slot="header">
                                                    À venir et passés
                                                </div>
                                                <div class="w-full bg-white p-2">
                                                    test
                                                </div>
                                            </vs-collapse-item>
                                            <vs-collapse-item class="rounded-lg mt-5" style="background-color: #F3F3F3;">
                                                <div class="font-semibold" slot="header">
                                                    juin . 2023
                                                </div>
                                            </vs-collapse-item>
                                        </vs-collapse>
                                    </div>
                                </div>
                            </vs-tab>
                            <vs-tab label="Détails">
                                <div class="w-full tab-text">
                                    <div class="w-full flex justify-end">
                                        <span class="text-danger mr-1">* </span> = informations requises
                                    </div>
                                    <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                                        <div>Informations sur le suspect</div>
                                    </div>
                                    <div class="w-full flex px-5">
                                        <div class="w-1/2 flex flex-col mr-10">
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 mr-2" for="status_suspect"><span class="text-danger">*</span>Status du suspect</label>
                                            <vs-select class="w-3/4" autocomplete v-validate="'required'" v-model="status_suspect" >
                                                <vs-select-item :key="index" :value="status" :text="status" v-for="(status, index) in ['Nouveau','Non qualifié','En cours de travail','Nurturing']" />
                                            </vs-select>
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 font-semibold mr-2" for="civilite"><span class="text-danger">*</span>Nom complet</label>
                                            <vs-select class="w-3/4" label="Civilité" autocomplete v-model="civilite" >
                                                <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['M.','Mme.','Dr.','Prof.']" />
                                            </vs-select>
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 font-semibold mr-2" for="prenom"></label>
                                            <vs-input class="w-3/4" type="text" label="Prénom" placeholder="Prénom" name="prenom" v-model="prenom" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 font-semibold mr-2" for="deuxieme_prenom"></label>
                                            <vs-input class="w-3/4" type="text" label="Deuxième prénom" placeholder="Deuxième prénom" name="deuxieme_prenom" v-model="deuxieme_prenom" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 font-semibold mr-2" for="nom"></label>
                                            <div class="w-3/4 flex flex-col">
                                                <vs-input class="w-full" type="text" label="*Nom" placeholder="Nom" name="nom" v-validate="'required'" v-model="nom" />
                                                <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
                                            </div>
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="fonction">Fonction</label>
                                                <vs-input class="w-3/4" type="text" placeholder="" name="fonction" v-model="fonction" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="email">Adresse e-mail</label>
                                                <vs-input class="w-3/4" type="email" placeholder="" name="email" v-model="email" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="telephone">Téléphone</label>
                                                <vs-input class="w-3/4" type="text" placeholder="" name="telephone" v-model="telephone" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="mobile">Téléphone mobile</label>
                                                <vs-input class="w-3/4" type="text" placeholder="" name="mobile" v-model="mobile" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="cote">Cote</label>
                                                <vs-select class="w-3/4" autocomplete v-model="cote" >
                                                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Haut','Moyen','Faible']" />
                                                </vs-select>
                                            </div>
                                        </div>
                                        <div class="w-1/2">
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 font-semibold mr-2" for="site_web">Propriétaire du suspect</label>
                                            <div class="w-3/4">
                                                {{ proprietaire ? proprietaire.nom : '' }}</div>
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="site_web">Site Web</label>
                                                <vs-input class="w-3/4" type="text" placeholder="" name="site_web" v-model="site_web" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 mr-2" for="societe"><span class="text-danger">*</span>Société</label>
                                            <div class="w-3/4 flex flex-col">
                                                <vs-input class="w-full" type="text" placeholder="" name="societe" v-validate="'required'" v-model="societe" />
                                                <span class="text-danger text-sm" v-show="errors.has('societe')">{{ errors.first('societe') }}</span>
                                            </div>
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="secteur">Secteur d'activité</label>
                                                <vs-select class="w-3/4" autocomplete v-model="secteur" >
                                                    <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Agriculture','Vêtements','Banque','Biotechnologie','Produits chimiques','Communications','Construction','Conseil','Formation','Électronique','Énergie','Ingénierie','Divertissement','Environnement','Finance','Alimentation & boissons','Gouvernement','Santé','Hôtellerie','Assurance','Machines','Fabrication','Médias','Non lucratif','Loisirs','Vente au détail','Livraison','Technologie','Télécommunications','Transport','Services','Autre']" />
                                                </vs-select>
                                            </div>
                                            <div class="w-full flex mt-5">
                                                <label class="w-1/4 mr-2" for="nmbre_employes">Nombre d'employés</label>
                                                <vs-input class="w-3/4" type="text" placeholder="" name="nmbre_employes" v-model="nmbre_employes" />
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <label class="w-1/4 mr-2" for="origine">Origine du suspect</label>
                                            <vs-select class="w-3/4" autocomplete v-model="origine" >
                                                <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Autre']" />
                                            </vs-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
                                        <div>Coordonnées</div>
                                    </div>
                                    <div class="w-full flex px-5 py-3">
                                        <div class="w-1/2">
                                            <h5 class="text-black font-semibold">Adresse</h5>
                                            <div class="w-full mt-5">
                                            <vs-textarea row="4" label="Rue" v-model="rue"></vs-textarea>
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <div class="w-3/5 mr-2">
                                                <vs-input class="w-full" type="text" label="Ville" placeholder="" name="ville" v-model="ville" />
                                            </div>
                                            <div class="w-2/5">
                                                <vs-input class="w-full" type="text" label="Région/Province" placeholder="" name="region" v-model="region" />
                                            </div>
                                            </div>
                                            <div class="w-full flex mt-5">
                                            <div class="w-3/5 mr-2">
                                                <vs-input class="w-full" type="text" label="Code postal" placeholder="" name="code_postal" v-model="code_postal" />
                                            </div>
                                            <div class="w-2/5">
                                                <vs-input class="w-full" type="text" label="Pays" placeholder="" name="pays" v-model="pays" />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full flex px-5 py-3">
                                        <div class="w-1/2 mr-10">
                                            <span class="font-semibold mr-5">Créé par:</span>
                                            <span class="mr-1">
                                            {{ proprietaire ? `${proprietaire.nom},` : '' }}
                                            </span>
                                            <span>
                                            {{ createdAt }}
                                            </span>
                                        </div>
                                        <div class="w-1/2">
                                            <span class="font-semibold mr-5">Dernière modification par:</span>
                                            <span class="mr-1">
                                            {{ proprietaire ? `${proprietaire.nom},` : '' }}
                                            </span>
                                            <span>{{ updatedAt }}</span>
                                        </div>
                                    </div>
                                    <div class="w-full flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
                                        <vs-button @click="showUpdateSuspectModal(null)" class="mr-2" color="danger" type="border">Annuler</vs-button>
                                        <vs-button @click="updateSuspectAndNew()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
                                        <vs-button @click="updateSuspect()">Enregistrer</vs-button> 
                                    </div>
                                </div>
                            </vs-tab>
                        </vs-tabs>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>
<script>
// For custom error message 
import { Validator } from 'vee-validate' 
const dict = { 
  custom: { 
    nom: { required: 'Remplissez le champ nom.', alpha: '' }, 
    societe: { required: 'Remplissez le champ société.', alpha: '' },
    nom_compte: { required: 'Remplissez le champ nom.'},
    nom_contact: { required: 'Remplissez le champ nom.'},
    nom_opportunite: { required: 'Remplissez le champ nom.'}
  } 
} 
// register custom messages 
Validator.localize('fr', dict)
export default {
  name: 'SuspectView',
  data () {
    return {
      popupCreateSuspect: false,
      popupUpdateSuspect: false,
      popupUpdateStatusSuspect: false,
      popupConversionSuspect: false,
      search_adresse: null,
      activeUserInfos: null,
      
      suspect: null,

      id_suspect: null,
      proprietaire: null,
      status_suspect: 'Nouveau',
      close_qualifier: false,
      close_nouveau: false,
      close_traitement: false,
      close_opportunite: false,
      close_converti: false,
      civilite: null,
      nom: null,
      prenom: null,
      deuxieme_prenom: null,
      suffixe: null,
      fonction: null,
      email: null,
      telephone: null,
      mobile: null,
      cote: null,
      site_web: null,
      societe: null,
      secteur: null,
      nmbre_employes: null,
      origine: null,
      rue: null,
      ville: null,
      region: null,
      pays: null,
      code_postal: null,

      createdAt: null,
      updatedAt: null,
      historique_update: [],
      //style
      currentClasse: 'current',
      completeClasse: 'complete',
      errorClass: 'error',
      //compte
      createCompte: 'créer compte',
      id_compte: null,
      nom_compte: null,
      description_compte: null,
      telephome_compte: null,
      mobile_compte: null,
      site_web_compte: null,
      type_compte: null,
      secteur_compte: null,
      nmbre_employes_compte: null,
      rue_compte: null,
      ville_compte: null,
      region_compte: null,
      pays_compte: null,
      code_postal_compte: null,
      rue_expedition_compte: null,
      ville_expedition_compte: null,
      region_expedition_compte: null,
      pays_expedition_compte: null,
      code_postal_expedition_compte: null,
      search_compte: null,
      search_compte_focus: false,
      search_compte_result: [],
      //contact
      createContact: 'créer contact',
      search_contact: null,
      search_contact_focus: false,
      search_contact_result: [],

      id_contact: null,
      civilite_contact: null,
      nom_contact: null,
      prenom_contact: null,
      deuxieme_prenom_contact: null,
      suffixe_contact: null,
      fonction_contact: null,
      email_contact: null,
      telephone_contact: null,
      mobile_contact: null,
      rue_contact: null,
      ville_contact: null,
      region_contact: null,
      pays_contact: null,
      code_postal_contact: null,

      //opportunite
      createOpportunite: 'créer opportunite',
      ne_pas_creer_opportunite: false,
      nom_opportunite: null,
      date_cloture: null,
      etape: 'Qualifiée',
      probabilte: null,
      categorie_prevision: 'Pipeline',
      search_proprietaire: null,
      search_proprietaire_focus: false,
      select_prorietaire: null,
      search_proprietaire_result: []
    }
  },
  computed: {
    stepClassObject () {
      return {}
    }
  },
  methods: {
    searchUser () {
      if (this.search_proprietaire !== null || this.search_proprietaire !== '' || this.search_proprietaire !== undefined) {
        this.$store.dispatch('userManagement/searchUser', this.search_proprietaire)
          .then((response) => {
            this.search_proprietaire_result = response.data.users
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Nous avons rencontré un problème',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    searchCompte () {
      if (this.search_compte !== null || this.search_compte !== undefined || this.search_compte !== '') {
        this.$store.dispatch('compte/searchCompte', this.search_compte)
          .then((response) => {
            this.search_compte_result = response.data.comptes
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Nous avons rencontré un problème',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    searchContact () {
      if (this.search_contact !== null || this.search_contact !== undefined || this.search_contact !== '') {
        this.$store.dispatch('compte/searchContact', this.search_contact)
          .then((response) => {
            this.search_contact_result = response.data.contacts
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Nous avons rencontré un problème',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    selectUser (user) {
      this.select_prorietaire = {
        _id: user._id,
        nom: user.nom,
        prenoms: user.prenoms
      }
    },
    showConvertiSuspect () {
      // compte
      this.nom_compte = this.societe
      this.telephome_compte = this.telephone
      this.mobile_compte = this.mobile
      this.site_web_compte = this.site_web
      this.nmbre_employes_compte = this.nmbre_employes
      this.secteur_compte = this.secteur
      this.rue_compte = this.rue
      this.ville_compte = this.ville
      this.region_compte = this.region
      this.pays_compte = this.pays
      this.code_postal_compte = this.code_postal

      // contact
      this.civilite_contact = this.civilite
      this.nom_contact = this.nom
      this.prenom_contact = this.prenom
      this.deuxieme_prenom_contact = this.deuxieme_prenom
      this.suffixe_contact = this.suffixe
      this.fonction_contact = this.fonction
      this.email_contact = this.email
      this.telephone_contact = this.telephone
      this.mobile_contact = this.mobile
      this.rue_contact = this.rue
      this.ville_contact = this.ville
      this.region_contact = this.region
      this.pays_contact = this.pays
      this.code_postal_contact = this.code_postal

      //opportunite
      this.nom_opportunite = `${this.societe}-`
      this.etape = 'Qualifiée'
      this.probabilte = 10
      this.categorie_prevision = 'Pipeline'
      const month = (new Date()).getMonth()
      const year = (new Date()).getFullYear()
      const d = new Date(year, month + 1, 0)
      //console.log(d.toString())
      this.date_cloture = d

      this.select_prorietaire = {
        _id: this.activeUserInfos._id,
        nom: this.activeUserInfos.nom,
        prenoms: this.activeUserInfos.prenoms
      }

      this.popupConversionSuspect = !this.popupConversionSuspect
    },
    showUpdateSuspectModal (suspect) {
      this.id_suspect = suspect ? suspect._id : null
      this.proprietaire = suspect ? {
        id: suspect.id_proprietaire,
        nom: suspect.nom_proprietaire
      } : null
      this.status_suspect = suspect ? suspect.status_suspect : null
      this.civilite = suspect ? suspect.civilite : null
      this.nom = suspect ? suspect.nom : null
      this.prenom = suspect ? suspect.prenom : null
      this.deuxieme_prenom = suspect ? suspect.deuxieme_prenom : null
      this.fonction = suspect ? suspect.fonction : null
      this.email = suspect ? suspect.email : null
      this.telephone = suspect ? suspect.telephone : null
      this.mobile = suspect ? suspect.mobile : null
      this.cote = suspect ? suspect.cote : null
      this.site_web = suspect ? suspect.site_web : null
      this.societe = suspect ? suspect.societe : null
      this.secteur = suspect ? suspect.secteur : null
      this.nmbre_employes = suspect ? suspect.nmbre_employes : null
      this.origine = suspect ? suspect.origine : null
      this.rue = suspect ? suspect.rue : null
      this.ville = suspect ? suspect.ville : null
      this.region = suspect ? suspect.region : null
      this.pays = suspect ? suspect.pays : null
      this.code_postal = suspect ? suspect.code_postal : null
      this.createdAt = suspect ? suspect.createdAt : null
      this.updatedAt = suspect ? suspect.updatedAt : null

      this.popupUpdateSuspect = !this.popupUpdateSuspect
    },
    validateSuspectStatus () {
      const payload = {
        id: this.id_suspect,
        id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
        id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
        nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
        status_suspect: this.status_suspect === 'Non qualifié' ? 'Nouveau' : this.status_suspect === 'Nouveau' ? 'Traitement' : this.status_suspect === 'Traitement' ? 'Opportunité' : this.status_suspect === 'Opportunité' ? 'Converti' : 'Converti'
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('suspect/validateSuspectStatus', payload)
        .then((response) => {
          this.$vs.notify({
            title: 'success',
            text: 'Vos modifications ont été enregistrées.',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.initSuspect(response)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    createSuspect () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: this.status_suspect,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            deuxieme_prenom: this.deuxieme_prenom,
            fonction: this.fonction,
            site_web: this.site_web,
            societe: this.societe,
            secteur: this.secteur,
            nmbre_employes: this.nmbre_employes,
            origine: this.origine,
            rue: this.rue,
            ville: this.ville,
            region: this.region,
            pays: this.pays,
            code_postal: this.code_postal
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('suspect/createSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.resete_data()
              this.$vs.loading.close()
              this.popupCreateSuspect = false
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }
      })
    },
    updateSuspect () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id: this.id_suspect,
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: this.status_suspect,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            deuxieme_prenom: this.deuxieme_prenom,
            fonction: this.fonction,
            email: this.email,
            telephone: this.telephone,
            mobile: this.mobile,
            cote: this.cote,
            site_web: this.site_web,
            societe: this.societe,
            secteur: this.secteur,
            nmbre_employes: this.nmbre_employes,
            origine: this.origine,
            rue: this.rue,
            ville: this.ville,
            region: this.region,
            pays: this.pays,
            code_postal: this.code_postal,
            historique_update: this.historique_update.push({
              id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
              nom: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
              date: Date.now()
            })
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('suspect/updateSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'Vos modifications ont été enregistrées',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.resete_data()
              this.showUpdateSuspectModal(null)
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }
      })
    },
    confirmDeleteSuspect () {
      this.id_suspect = this.suspect ? this.suspect._id : null
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer le suspect',
        text: 'Voulez-vous vraiment supprimer ce suspect ?',
        acceptText: 'Supprimer',
        accept: this.deleteSuspect
      })
    },
    deleteSuspect () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('suspect/softdeleteSuspect', this.id_suspect)
        .then(() => {
          this.$vs.notify({
            title: 'Suspect',
            text: 'Le suspect a été supprimé',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'primary'
          })
          this.$router.push('/commercial/suspects')
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    convertirSuspectCompteContactOpportunite  () {
      if (this.createCompte === 'créer compte' && this.createContact === 'créer contact' && this.createOpportunite === 'créer opportunite' && this.ne_pas_creer_opportunite === false) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$vs.loading({
              type:'sound'
            })
            const payload_compte = {
              id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
              id_proprietaire: this.select_prorietaire ? this.select_prorietaire._id : null,
              nom_proprietaire: this.select_prorietaire ? `${this.select_prorietaire.nom} ${this.select_prorietaire.prenoms}` : null,
              nom: this.nom_compte,
              description: this.description_compte,
              telephone: this.telephome_compte,
              mobile: this.mobile_compte,
              site_web: this.site_web_compte,
              secteur: this.secteur_compte,
              nmbre_employes: this.nmbre_employes_compte,
              rue: this.rue_compte,
              ville: this.rue_compte,
              region: this.region_compte,
              pays: this.pays_compte,
              code_postal: this.code_postal_compte,
              rue_expedition: this.rue_expedition_compte,
              ville_expedition: this.ville_expedition_compte,
              region_expedition: this.region_expedition_compte,
              pays_expedition: this.pays_expedition_compte,
              code_postal_expedition: this.code_postal_expedition_compte
            }
            this.$store.dispatch('compte/createCompte', payload_compte)
              .then((response) => {
                this.id_compte = response.data.compteSale._id
                this.popupConversionSuspect = !this.popupConversionSuspect
                this.$vs.loading.close()
                this.createNewContact()
                this.createNewOpportunite()
              })
              .catch(err => {
                console.error(err)
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'Nous avons rencontré un problème',
                  text: `${err}`,
                  iconPack: 'feather',
                  icon: 'icon-error',
                  color: 'danger'
                })
              })
          }
        })
      }
    },
    convertiSuspect () {
      const payload = {
        id: this.id_suspect,
        id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
        id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
        nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
        status_suspect: 'Converti'
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('suspect/convertiSuspect', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'Vos modifications ont été enregistrées.',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$router.push('/commercial/suspects')
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    createNewContact () {
      const payload = {
        id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
        id_proprietaire: this.select_prorietaire ? this.select_prorietaire._id : null,
        nom_proprietaire: this.select_prorietaire ? `${this.select_prorietaire.nom} ${this.select_prorietaire.prenoms}` : null,
        id_compte: this.id_compte,
        nom_compte: this.nom_compte,
        civilite: this.civilite_contact,
        nom: this.nom_contact,
        prenom: this.prenom_contact,
        deuxieme_prenom: this.deuxieme_prenom,
        suffixe: this.suffixe_contact,
        fonction: this.fonction_contact,
        email: this.email_contact,
        telephone: this.telephone_contact,
        mobile: this.mobile_contact,
        fax: null,
        responsable: null,
        service: null,
        rue: this.rue_contact,
        ville: this.ville_contact,
        region: this.region_contact,
        pays: this.pays_contact,
        code_postal: this.code_postal_contact
      }

      this.$store.dispatch('contact/createContact', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    createNewOpportunite () {
      const payload = {
        id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
        id_proprietaire: this.select_prorietaire ? this.select_prorietaire._id : null,
        nom_proprietaire: this.select_prorietaire ? `${this.select_prorietaire.nom} ${this.select_prorietaire.prenoms}` : null,
        id_compte: this.id_compte,
        nom_compte: this.nom_compte,
        id_suspect: this.id_suspect,
        nom: this.nom_opportunite,
        nomtant: null,
        date_cloture: this.date_cloture,
        etape: this.etape,
        probabilte: this.etape === 'Qualifiée' ? 10 : 0,
        categorie_prevision: this.categorie_prevision,
        etape_suivante: null,
        type_opportunite: null,
        origine: null,
        description: null,
        contrats: [],
        devis: []
      }

      this.$store.dispatch('opportunite/createOpportunite', payload)
        .then(() => {
          this.$vs.loading.close()
          this.convertiSuspect()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getSuspectById () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('suspect/getSuspectById', this.$route.params.id)
        .then((response) => {
          this.initSuspect(response)
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    initSuspect (response) {
      this.suspect = response.data.suspect

      this.id_suspect = response.data.suspect._id
      this.proprietaire = response.data.suspect.proprietaire
      this.status_suspect = response.data.suspect.status_suspect
      this.close_qualifier = response.data.suspect.close_qualifier
      this.close_nouveau = response.data.suspect.close_nouveau
      this.close_traitement = response.data.suspect.close_traitement
      this.close_opportunite = response.data.suspect.close_opportunite
      this.close_converti = response.data.suspect.close_converti
      this.civilite = response.data.suspect.civilite
      this.nom = response.data.suspect.nom
      this.prenom = response.data.suspect.prenom
      this.deuxieme_prenom = response.data.suspect.deuxieme_prenom
      this.suffixe = response.data.suspect.suffixe
      this.fonction = response.data.suspect.fonction
      this.email = response.data.suspect.email
      this.telephone = response.data.suspect.telephone
      this.mobile = response.data.suspect.mobile
      this.cote = response.data.suspect.cote
      this.site_web = response.data.suspect.site_web
      this.societe = response.data.suspect.societe
      this.secteur = response.data.suspect.secteur
      this.nmbre_employes = response.data.suspect.nmbre_employes
      this.origine = response.data.suspect.origine
      this.rue = response.data.suspect.rue
      this.ville = response.data.suspect.ville
      this.region = response.data.suspect.region
      this.pays = response.data.suspect.pays
      this.code_postal = response.data.suspect.code_postal
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.activeUserInfo()
    this.getSuspectById()
  }
}
</script>
<style scoped>
.stepper {
  list-style: none;
}
.stepper::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  width: calc(100% - 20px);
  background: #ffffff;
}

.stepper__item {
  flex: 100%;
  padding: 20px 20px 20px 40px;
  background: #F3F3F3;
  margin: 0 0 0 -15px;
  -webkit-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}
.stepper__item.current {
  background: #014486 !important;
  color: white !important;
  font-weight: bold !important;
}
.stepper__item.complete {
  background: #3BA755;
  color: white;
}
.stepper__item:first-child {
  padding: 20px;
  -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}
.stepper__item:last-child {
  -webkit-clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

</style>